<template>
  <div id="app">
    <div class="bg_green">
      <div class="white_box" style="padding-top:3vw;">
        <div class="pic_list2">
          <dl class="clear">
            <dt :style="{backgroundImage:`url(${pic1})`}" style="background-size: 100% 100%;"></dt>

            <!-- 标题 -->
            <dd class="title2" style="height: auto; font-size: 14px; line-height: 14px;">{{ nutritive.name }}</dd>

            <!-- 菜品列表 -->
            <dd v-if="nutritive.mainCourse"><span style="color:#818181;">主菜: </span>{{ nutritive.mainCourse }}</dd>
            <dd v-if="nutritive.sideDish"><span style="color:#818181;">配菜: </span>{{ nutritive.sideDish }}</dd>
            <dd v-if="nutritive.vegetables"><span style="color:#818181;">时蔬: </span>{{ nutritive.vegetables }}</dd>
            <dd v-if="nutritive.stapleFood"><span style="color:#818181;">主食: </span>{{ nutritive.stapleFood }}</dd>
            <dd v-if="nutritive.snack"><span style="color:#818181;">小食: </span>{{ nutritive.snack }}</dd>
            <dd v-if="nutritive.soup"><span style="color:#818181;">汤品: </span>{{ nutritive.soup }}</dd>

          </dl>
        </div>

      </div>
    </div>

    <!-- 餐食详情 -->
    <div class="meals_details">
      <div class="meals_details_t" style="margin-top: 8px;">
        <ul class="clear">
          <li @click="cur=0" :class="{now:cur==0}">
            <span>营养元素</span>
          </li>
          <li @click="cur=1" :class="{now:cur==1}">
            <span>溯源信息</span>
          </li>
        </ul>
      </div>

      <!-- 营养元素 -->
      <div class="meals_details_b" v-show="cur==0">

        <div id="container" style="display: flex; padding: 16px;">

          <div style="height: 88px; width: 88px; border: 12px #F8D7E6 solid; border-radius: 100px; text-align: center; padding-top: 12px; margin-left: 8px;">
            <div style="font-size: 22px; line-height: 22px;">{{ nutritive.nutriEnergy ? (nutritive.nutriEnergy * 0.2389).toFixed(0) : '0' }}</div>
            <div style="font-size: 14px; line-height: 14px; margin-top: 4px;">{{ '千卡' }}</div>
          </div>

          <div style="margin-left: 20px; flex: 1; padding-top: 8px;">
            <div style="display: flex; align-items: center">
              <div style="background: #F9F0D6; height: 14px; width: 14px;"></div>
              <div style="font-size: 14px; line-height: 14px; flex: 1; margin-left: 4px;">{{ '蛋白质' }}</div>
              <div style="width: 56px; font-size: 14px; line-height: 14px;">{{ (nutritive.nutriProtein ? nutritive.nutriProtein.toFixed(0) : '0') + ' g' }}</div>
            </div>
            <div style="display: flex; align-items: center; margin-top: 15px;">
              <div style="background: #F4FAFA; height: 14px; width: 14px;"></div>
              <div style="font-size: 14px; line-height: 14px; flex: 1; margin-left: 4px;">{{ '脂肪' }}</div>
              <div style="width: 56px; font-size: 14px; line-height: 14px;">{{ (nutritive.nutriFat ? nutritive.nutriFat.toFixed(0) : '0') + ' g' }}</div>
            </div>
            <div style="display: flex; align-items: center; margin-top: 15px;">
              <div style="background: #F4E2B1; height: 14px; width: 14px;"></div>
              <div style="font-size: 14px; line-height: 14px; flex: 1; margin-left: 4px;">{{ '碳水化合物' }}</div>
              <div style="width: 56px; font-size: 14px; line-height: 14px;">{{ (nutritive.nutriCarbohydrate ? nutritive.nutriCarbohydrate.toFixed(0) : '0') + ' g' }}</div>
            </div>
          </div>
        </div>

        <table style="width:100%">
          <tr>
            <th>项目</th>
            <th>每份</th>
          </tr>
          <tr v-if="nutritive.nutriCalcium">
            <td>钙</td>
            <td>{{ nutritive.nutriCalcium.toFixed(2) }} mg</td>
          </tr>
          <tr v-if="nutritive.nutriIron">
            <td>铁</td>
            <td>{{ nutritive.nutriIron.toFixed(2) }} mg</td>
          </tr>
          <tr v-if="nutritive.nutriZinc">
            <td>锌</td>
            <td>{{ nutritive.nutriZinc.toFixed(2) }} mg</td>
          </tr>
          <tr v-if="nutritive.nutriRetinol">
            <td>维生素A</td>
            <td>{{ nutritive.nutriRetinol.toFixed(2) }} μg</td>
          </tr>
          <tr v-if="nutritive.nutriAneurin">
            <td>维生素B1(硫胺素)</td>
            <td>{{ nutritive.nutriAneurin.toFixed(2) }} mg</td>
          </tr>
          <tr v-if="nutritive.nutriRiboflavin">
            <td>维生素B2(核黄素)</td>
            <td>{{ nutritive.nutriRiboflavin.toFixed(2) }} mg</td>
          </tr>
          <tr v-if="nutritive.nutriAscorbicAcid">
            <td>维生素C</td>
            <td>{{ nutritive.nutriAscorbicAcid.toFixed(2) }} mg</td>
          </tr>
          <tr v-if="nutritive.nutriMealFibre">
            <td>膳食纤维</td>
            <td>{{ nutritive.nutriMealFibre.toFixed(2) }} g</td>
          </tr>
        </table>
      </div>

      <!-- 溯源信息 -->
      <div class="meals_details_2" v-show="cur==1" style="margin-top: 12px;">
        <ul>
          <li v-for="(option,index) in traceDataList" :key="index" @click="jumpSourceDetail(option)">
            <span>{{ option.name }}</span>
            <label v-if="option.traceUrl"><img src="../assets/images/arrow.png" alt=""></label>
          </li>
        </ul>
      </div>

    </div>

    <div class="height_5vw"></div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>

<style scoped>
table {
  margin-bottom: 0;
}

table tr {
  border: none;
}
</style>

<script>
import Vue from 'vue'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import {NUTRIENT_SOURCE} from "../store/mutation-types";
import {Dialog} from "vant";
export default {
  data() {
    return {
      pic1: require('../assets/images/pic_default.jpg'),
      // tab
      loading: false,
      cur: 0,
      id: null,
      rq: null,
      customerUser: {},
      nutritive: {},
      traceDataList: [],
    };
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    this.id = this.$route.query.id;

    this.nutritive = Vue.ls.get(NUTRIENT_SOURCE);
    if (!this.nutritive || !this.id || this.nutritive.id !== this.id) {
      Dialog.alert({title: '餐食营养显示异常'})
          .then(() => {
            this.goBack();
          })
      return;
    }
    if (this.nutritive.thumbnailImage) {
      this.pic1 = this.nutritive.thumbnailImage;
    }

    this.customerUser = Vue.ls.get(ACCESS_CustomerUser)
    this.rq = this.$route.query.rq;
    this.cur = this.$route.query.suyuan ? 1 : 0

    this.loadTrace();
  },
  methods: {
    goBack() {
      this.$router.push('/index');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    jumpSourceDetail(data) {
      if (!data.traceUrl) return;

      // Vue.ls.set(NUTRIENT_SOURCE_TRACE, data);
      //
      // this.$router.push({
      //   path: '/source_detail',
      //   query: {materialId: '', rq: this.rq, id: this.id}
      // })

      window.location.href = data.traceUrl;
    },
    loadTrace() {
      let businessId = this.customerUser.businessCustomerUser.business.id

      const url = 'api/business/v2/{businessId}/dietary/nutrition/read/recipes/{dietaryNutritionRecipesId}/trace'
          .replace('{businessId}', businessId)
          .replace('{day}', this.rq)
          .replace('{dietaryNutritionRecipesId}', this.id)

      post(url, {}, true).then(res => {
        this.traceDataList = res.data;
      }).catch(() => {

      });
    },
  }
};
</script>
